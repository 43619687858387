import { Directive, HostBinding } from '@angular/core';

/**
 * For the action buttons in a dialog. Replacement for `mat-dialog-actions`.
 * @see {@link PadspinDialogTitleDirective}
 * @see {@link PadspinDialogContentDirective}
 */
@Directive({
  selector: '[padspinDialogActions]',
  standalone: true,
})
export class PadspinDialogActionsDirective {
  @HostBinding('class.padspin_dialog_actions') readonly class = true;
}
