import { inject, Injectable } from '@angular/core';
import { getDoc, getFirestore, doc } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { Capacitor } from '@capacitor/core';
import { firstValueFrom } from 'rxjs';
import { ModalLazyLoaderService } from '@padspin/ui-modal';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  private readonly mlls = inject(ModalLazyLoaderService);

  /**
   * If we are running in an app (Android or iOS), we need to ensure we
   * are not running an out-of-date version. If we are running an
   * out-of-date version of the app, force the user to upgrade their
   * installation before continuing.
   * Note: We of course want to remain backwards-compatible. This is for
   * use in emergencies.
   */
  async checkMinVersion() {
    const currentVersion = environment.version;
    const minVersion = await this.minVersion();
    if (typeof minVersion !== 'string') {
      return;
    }
    if (this.compareSemver(currentVersion, minVersion) !== -1) {
      return;
    }
    const dialogRef = await this.mlls.openCDKModal(
      {
        import: () => import('./modals/version-modal/version-modal.component'),
      },
      {
        backdropClass: 'modal_backdrop',
        panelClass: 'modal_panel',
        hasBackdrop: true,
        disableClose: true,
      }
    );
    return firstValueFrom(dialogRef.closed);
  }

  private async minVersion(): Promise<string | null> {
    const snap = await getDoc(doc(getFirestore(), 'version', 'minimum'));
    const version = snap.data() as
      | {
          android: string;
          ios: string;
          web: string;
        }
      | undefined;
    if (!version) {
      console.info(`Database does not have a minimum version`);
      return 'null';
    }
    const platform = Capacitor.getPlatform();
    return platform === 'ios'
      ? version.ios
      : platform === 'android'
      ? version.android
      : version.web;
  }

  compareSemver(a: string, b: string): 1 | 0 | -1 {
    const pa = a.split('.');
    const pb = b.split('.');
    for (let i = 0; i < 3; i++) {
      const na = Number(pa[i]);
      const nb = Number(pb[i]);
      if (na > nb) return 1;
      if (nb > na) return -1;
      if (!isNaN(na) && isNaN(nb)) return 1;
      if (isNaN(na) && !isNaN(nb)) return -1;
    }
    return 0;
  }
}
