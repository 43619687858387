import { Injectable, inject } from '@angular/core';
import { Auth, idToken } from '@angular/fire/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FireAuthIdTokenService {
  private readonly auth = inject(Auth);
  idToken(): Observable<string | null> {
    return idToken(this.auth);
  }
}
