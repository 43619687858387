import { Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { SideNavService } from '../side-nav.service';
import { HeaderService, HeaderType } from '../header.service';
import {
  asyncScheduler,
  combineLatest,
  combineLatestAll,
  Observable,
  scheduled,
  startWith,
  Subscription,
} from 'rxjs';
import { AuthService } from '../auth.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { MenuItem } from 'primeng/api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { PadspinButtonComponent } from '@padspin/ui-button';
import { MenuModule } from 'primeng/menu';
import { AsyncPipe } from '@angular/common';
import { Button } from 'primeng/button';

@Component({
  selector: 'padspin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    PadspinButtonComponent,
    RouterModule,
    MenuModule,
    AsyncPipe,
    Button,
  ],
})
export class HeaderComponent implements OnDestroy {
  isSavedPadsVisible$ = this.headerService.savePadsVisible$;
  isViewingsVisible$ = this.headerService.viewingsVisible$;
  user$ = this.authService.user$;
  isTenantDashboardVisible$ = scheduled(
    [this.authService.isTenant$, this.authService.isAdmin$],
    asyncScheduler
  ).pipe(
    combineLatestAll(),
    map((is) => is[0] || is[1])
  );
  isLandlordDashboardVisible$ = scheduled(
    [this.authService.isLandlord$, this.authService.isAdmin$],
    asyncScheduler
  ).pipe(
    combineLatestAll(),
    map((is) => is[0] || is[1])
  );
  isAdminDashboardVisible$ = this.authService.isAdmin$;
  currentRoute$ = this.router.events.pipe(
    filter((e) => e instanceof NavigationEnd),
    map(() => this.router.url)
  );
  isLandlordLandingVisible$ = this.currentRoute$.pipe(
    map((route) => route === '/')
  );

  isTenantLandingVisible$ = this.currentRoute$.pipe(
    map((route) => route === '/rent')
  );

  isAnnouncementBarVisible = true;

  bgColor: HeaderType = 'transparent';
  @Input() bg: HeaderType = 'transparent';
  contrastColor: 'green' | 'blue' = 'green';
  isTenant = false;
  isLanding = false;

  /**
   * Add a css class to the component if the header is transparent, so this
   * component's styling can account for the extra padding it will need in lieu
   * of a header component.
   */
  @HostBinding('class.overlay') zeroHeight: boolean =
    this.bgColor === 'transparent';

  private subscriptions: Subscription[] = [];

  protected primengMenuItems$: Observable<MenuItem[]> = combineLatest([
    this.authService.user$.pipe(startWith(null)),
    this.isTenantDashboardVisible$.pipe(startWith(false)),
    this.isLandlordDashboardVisible$.pipe(startWith(false)),
    this.isAdminDashboardVisible$.pipe(startWith(false)),
  ]).pipe(
    map(([maybeUser, isTenant, isLandlord, isAdmin]) => {
      const accountActionMenuItem: MenuItem = maybeUser
        ? {
            label: 'Logout',
            command: () => this.logout(),
          }
        : {
            label: 'Login / Sign Up',
            command: () => this.showLoginDialog(),
          };
      const menuItems: MenuItem[] = [
        { label: 'Post Pad', routerLink: '/landlord' },
        accountActionMenuItem,
      ];
      if (isTenant) {
        menuItems.push({
          label: 'Tenant Dashboard',
          routerLink: '/dashboard/tenant',
        });
      }
      if (isLandlord) {
        menuItems.push({
          label: 'Landlord Dashboard',
          routerLink: '/dashboard/landlord',
        });
      }
      if (isAdmin) {
        menuItems.push({
          label: 'Admin Dashboard',
          routerLink: '/dashboard/admin',
        });
      }
      return menuItems;
    }),
    takeUntilDestroyed()
  );

  constructor(
    private readonly sideNavService: SideNavService,
    private readonly headerService: HeaderService,
    public readonly authService: AuthService,
    private readonly analytics: GoogleAnalyticsService,
    private readonly router: Router
  ) {
    if (this.bg !== undefined) {
      this.headerService.setType(this.bg);
    }
    this.subscriptions.push(
      this.headerService.bgColor$.subscribe((t) => {
        this.bg = t;
        this.zeroHeight = t === 'transparent';
      }),
      this.headerService.contrastColor$.subscribe((t) => {
        this.contrastColor = t;
      }),
      this.headerService.isTenantObvs$.subscribe((t) => {
        this.isTenant = t;
      }),
      this.headerService.isLandingObvs$.subscribe((t) => {
        this.isLanding = t;
      })
    );
    if (
      Capacitor.getPlatform() === 'ios' ||
      Capacitor.getPlatform() === 'android'
    ) {
      StatusBar.setStyle({ style: Style.Dark });
    }
  }

  toggleMenu(): void {
    this.sideNavService.toggleSideNav();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  async logout(): Promise<void> {
    await this.authService.signOut();
  }

  async showLoginDialog(): Promise<void> {
    this.analytics.log('click_login');
    await this.authService.showOnboardingDialog();
  }

  async onPostPadClick(): Promise<void> {
    return this.analytics.log('click_post_pad');
  }

  async onLogoClick(): Promise<void> {
    return this.analytics.log('click_header_logo');
  }

  toggleAnnouncementVisibility() {
    this.isAnnouncementBarVisible = !this.isAnnouncementBarVisible;
  }
}
