import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { MessageService, ToastMessageOptions } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { SnackbarService } from '../snackbar.service';
import { tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, combineLatest } from 'rxjs';

/**
 * Wrapper for the PrimeNG <p-toast> component that displays messages from the
 * SnackbarService.
 */
@Component({
  selector: 'padspin-toast',
  imports: [ToastModule],
  template: `<p-toast />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class ToastComponent {
  private defaults$ = new BehaviorSubject<ToastMessageOptions>({});

  /** Default properties for every message */
  @Input() set defaults(partial: Partial<ToastMessageOptions>) {
    this.defaults$.next(partial);
  }

  private readonly messageService = inject(MessageService);
  private readonly _toastSubscription = combineLatest([
    inject(SnackbarService).getMessages(),
    this.defaults$,
  ])
    .pipe(
      tap(([message, defaults]) =>
        this.messageService.add({ ...defaults, ...message })
      ),
      takeUntilDestroyed()
    )
    .subscribe();
}
