import { Injectable, inject } from '@angular/core';
import { Auth, user, User } from '@angular/fire/auth';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class FireAuthUserService {
  private readonly auth = inject(Auth);
  user(): Observable<User | null> {
    return user(this.auth);
  }
}
