import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FrontendComponent } from './frontend/frontend.component';

/**
 * The default entrypoint for the web library.
 *
 * This component requires the `routes` and `applicationConfigProviders` to be
 * provided to the root component of the application:
 *
 * ```typescript
 * import { routes, applicationConfigProviders, WebComponent } from '@padspin/web';
 * export const appConfig: ApplicationConfig = {
 *   providers: [
 *     provideRouter(routes),
 *     provideClientHydration(),
 *     ...applicationConfigProviders,
 *   ],
 * };
 * function bootstrap() {
 *   bootstrapApplication(MyRootComponent, appConfig);
 * }
 * @Component({
 *   selector: 'my-root',
 *   template: `<padspin-web></padspin-web>`,
 *   standalone: true,
 *   imports: [WebComponent],
 * })
 * export class AppComponent {}
 * bootstrap();
 * ```
 */
@Component({
  selector: 'padspin-web',
  template: '<padspin-frontend></padspin-frontend>',
  styles: [
    `
      :host {
        height: 100%;
      }
    `,
  ],
  imports: [FrontendComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebComponent {}
