import {
  EnvironmentProviders,
  makeEnvironmentProviders,
  NgModule,
} from '@angular/core';
import {
  provideHttpClient,
  withJsonpSupport,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MapLoadingService } from './map-loading.service';

export function provideLoadingService(): EnvironmentProviders {
  return makeEnvironmentProviders([
    MapLoadingService,
    provideHttpClient(withJsonpSupport()),
  ]);
}

@NgModule({
  providers: [
    MapLoadingService,
    provideHttpClient(withJsonpSupport()),
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
  ],
})
export class MapLoadingServiceModule {}
