export const remoteConfigUpdateI18nFunctionName =
  'remote_config_update_i18n' as const;

export type RemoteConfigUpdateI18nRequestType = {
  value: string | number | boolean;
  key: string;
};

export type RemoteConfigUpdateI18nReturnType = {
  success: boolean;
  error?: unknown;
};
