import { Injectable, inject } from '@angular/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { OnboardingInput } from './modals/onboarding-modal/onboarding-input.type';
import { LoginOrRegisterInputType } from './modals/login-or-register-modal/login-or-register-input.type';
import { UserType } from '@padspin/models';
import {
  User,
  UserCredential,
  ActionCodeSettings,
  EmailAuthProvider,
  EmailAuthCredential,
  ParsedToken,
} from '@angular/fire/auth';
import {
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  signInWithEmailAndPassword,
  linkWithCredential,
  Auth,
  authState,
} from '@angular/fire/auth';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeUserTypeRequestData,
  ChangeUserTypeResponse,
  changeUserTypeName,
  listUsersName,
  ListUsersResponse,
  ListUsersRequestData,
  DeleteUserRequestData,
  DeleteUserResponse,
  deleteUserName,
  createUserName,
  CreateUserRequestData,
  CreateUserResponse,
  getUserCustomClaimsName,
  GetUserCustomClaimsRequestData,
  GetUserCustomClaimsResponse,
} from '@padspin/function-types';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { CustomClaims } from '@padspin/rbac';
import { ModalLazyLoaderService } from '@padspin/ui-modal';
import { FireAuthIdTokenService } from './fire-auth-id-token.service';
import { FireAuthUserService } from './fire-auth-user.service';
import { FireAuthAuthStateService } from './fire-auth-auth-state.service';

export type UserClaims = ParsedToken &
  Record<'tenant' | 'landlord' | 'admin', boolean>;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly fireauth = inject(Auth);
  private readonly functions = inject(Functions);
  private readonly mlls = inject(ModalLazyLoaderService);
  private readonly idTokenService = inject(FireAuthIdTokenService);
  private readonly userService = inject(FireAuthUserService);
  private readonly authStateService = inject(FireAuthAuthStateService);

  user$ = this.userService.user();
  idToken$ = this.idTokenService.idToken();
  authState$ = this.authStateService.authState();
  readonly currentUser$: Observable<User | undefined> = this.user$.pipe(
    map((u) => u ?? undefined),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
  currentUserUID$ = this.currentUser$.pipe(map((cu) => cu?.uid));
  readonly currentUserClaims$: Observable<CustomClaims> =
    this.currentUser$.pipe(
      switchMap(async (u) => {
        const result = await u?.getIdTokenResult();
        if (!result) {
          return {
            admin: false,
            landlord: false,
            tenant: false,
            account_manager: false,
            owner: false,
          };
        }
        return result.claims as CustomClaims;
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  currentUserType$: Observable<UserType> = this.currentUserClaims$.pipe(
    map((claims) => (claims.tenant ? 'tenant' : 'landlord'))
  );
  isTenant$: Observable<boolean> = this.user$.pipe(
    map((u: User | null) => (u ? u.getIdTokenResult() : of(u))),
    switchMap((result) => result),
    map((r) => (r ? coerceBooleanProperty(r.claims['tenant']) : false))
  );
  isLandlord$: Observable<boolean> = this.user$.pipe(
    map((u: User | null) => (u ? u.getIdTokenResult() : of(u))),
    switchMap((result) => result),
    map((r) => (r ? coerceBooleanProperty(r.claims['landlord']) : false))
  );
  isAdmin$: Observable<boolean> = this.user$.pipe(
    map((u) => (u ? u.getIdTokenResult() : of(u))),
    switchMap((result) => result),
    map((r) => (r ? coerceBooleanProperty(r.claims['admin']) : false))
  );
  isOwner$: Observable<boolean> = this.user$.pipe(
    map((u) => (u ? u.getIdTokenResult() : of(u))),
    switchMap((result) => result),
    map((r) => (r ? coerceBooleanProperty(r.claims['owner']) : false))
  );

  async signOut(): Promise<void> {
    return this.fireauth.signOut();
  }

  async sendPasswordResetEmail(
    email: string,
    settings?: ActionCodeSettings | undefined
  ): Promise<void> {
    return sendPasswordResetEmail(this.fireauth, email, settings);
  }

  /** @returns email */
  async verifyPasswordResetCode(code: string): Promise<string> {
    return verifyPasswordResetCode(this.fireauth, code);
  }

  async confirmPasswordReset(code: string, password: string): Promise<void> {
    return confirmPasswordReset(this.fireauth, code, password);
  }

  async signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.fireauth, email, password);
  }

  async createUserWithEmailAndPassword(data: {
    email: string;
    password: string;
    type: 'tenant' | 'landlord';
    first_name: string;
    last_name: string;
    phone: string;
  }): Promise<void> {
    const callable$ = httpsCallableData<
      CreateUserRequestData,
      CreateUserResponse
    >(this.functions, createUserName);
    const result = await firstValueFrom(callable$(data));
    if (result.error) {
      throw result.error;
    }
    if (result.success) {
      // The first Firestore or Storage write will fail without a delay,
      // presumably because the firestore rules take a moment to update.
      await new Promise((r) => setTimeout(r, 200));
      return;
    }
  }

  async linkWithCredential(
    email: string,
    password: string
  ): Promise<UserCredential> {
    const cred: EmailAuthCredential = EmailAuthProvider.credential(
      email,
      password
    );
    const usr = this.fireauth.currentUser;
    if (!usr) {
      throw new Error('NOT LOGGED IN');
    }
    return linkWithCredential(usr, cred);
  }

  /**
   * If you cannot take an action before the user is logged in, subscribe to
   * this, and take your action after user becomes truthy.
   * @deprecated Replaced with this.authState$
   */
  onAuthStateChange(): Observable<User | null> {
    return authState(this.fireauth).pipe(filter((u) => u !== null));
  }

  /** @deprecated Make reactive. */
  get user(): User | null {
    return this.fireauth.currentUser;
  }

  async showOnboardingDialog(
    data: OnboardingInput = { login: true }
  ): Promise<void> {
    const dialogRef = await this.mlls.openCDKModal(
      {
        import: () =>
          import('./modals/onboarding-modal/onboarding-modal.component'),
      },
      {
        backdropClass: 'modal_backdrop',
        panelClass: 'modal_panel',
        hasBackdrop: true,
        disableClose: false,
        data,
      }
    );
    await firstValueFrom(dialogRef.closed);
  }

  /** @deprecated AttemptLoginService.attemptLogin */
  async attemptLogin(
    data: LoginOrRegisterInputType = {}
  ): Promise<UserCredential | undefined> {
    const dialogRef = await firstValueFrom(
      this.mlls.openCDKModal$<
        UserCredential | undefined,
        LoginOrRegisterInputType
      >(
        {
          import: () =>
            import(
              './modals/login-or-register-modal/login-or-register-modal.component'
            ),
        },
        {
          data,
          backdropClass: 'modal_backdrop',
          disableClose: true,
        }
      )
    );
    try {
      return firstValueFrom(dialogRef.closed);
    } catch (err) {
      console.warn(
        `Caught error while attempting to lazily open the modal`,
        err
      );
      return undefined;
    }
  }

  async setUserType(options: {
    type: 'landlord' | 'tenant' | null;
    customClaims?: Partial<CustomClaims>;
    uid?: string;
  }): Promise<ChangeUserTypeResponse | undefined> {
    const currentUser = this.fireauth.currentUser;
    if (!currentUser || !currentUser.uid) {
      throw new Error('MUST_BE_LOGGED_IN');
    }
    const currentUserIdToken = await currentUser.getIdToken(true);
    const data: ChangeUserTypeRequestData = {
      uid: options.uid ?? currentUser.uid,
      type: options.type,
      admin:
        typeof options.customClaims?.admin !== 'undefined'
          ? options.customClaims?.admin
          : null,
      owner:
        typeof options.customClaims?.owner !== 'undefined'
          ? options.customClaims?.owner
          : null,
      account_manager:
        typeof options.customClaims?.account_manager !== 'undefined'
          ? options.customClaims?.account_manager
          : null,
      currentUserIdToken,
    };
    const callable$ = httpsCallableData<
      ChangeUserTypeRequestData,
      ChangeUserTypeResponse
    >(this.functions, changeUserTypeName);
    return await firstValueFrom(callable$(data));
  }

  async getUserCustomClaims(uid: string): Promise<CustomClaims | null> {
    const callable$ = httpsCallableData<
      GetUserCustomClaimsRequestData,
      GetUserCustomClaimsResponse
    >(this.functions, getUserCustomClaimsName);
    try {
      return await firstValueFrom(callable$({ uid }));
    } catch (_error) {
      return null;
    }
  }

  async listUsers(
    config: { withClaim?: keyof CustomClaims } = {}
  ): Promise<ListUsersResponse | undefined> {
    const callable$ = httpsCallableData<
      ListUsersRequestData,
      ListUsersResponse
    >(this.functions, listUsersName);
    const currentUser = this.fireauth.currentUser;
    if (!currentUser || !currentUser.uid) {
      throw new Error('MUST_BE_LOGGED_IN');
    }
    const currentUserIdToken = await currentUser.getIdToken(true);
    const listUserRequestData: ListUsersRequestData = {
      currentUserIdToken,
      customClaim: config.withClaim ?? undefined,
    };
    return await firstValueFrom(callable$(listUserRequestData));
  }

  async deleteUser(uid: string): Promise<void> {
    const callable$ = httpsCallableData<
      DeleteUserRequestData,
      DeleteUserResponse
    >(this.functions, deleteUserName);
    const currentUser = this.fireauth.currentUser;
    if (!currentUser || !currentUser.uid) {
      throw new Error('MUST_BE_LOGGED_IN');
    }
    const currentUserIdToken = await currentUser.getIdToken(true);
    await firstValueFrom(callable$({ uid, currentUserIdToken }));
  }
}
