import { Directive, HostBinding } from '@angular/core';

/**
 * For the title in a dialog. Replacement for `mat-dialog-title`.
 * @see {@link PadspinDialogContentDirective}
 * @see {@link PadspinDialogActionsDirective}
 */
@Directive({
  selector: '[padspinDialogContent]',
  standalone: true,
})
export class PadspinDialogContentDirective {
  @HostBinding('class.padspin_dialog_content') readonly class = true;
}
