import { definePreset, palette } from '@primeng/themes';
import Lara from '@primeng/themes/lara';
import { ThemeType } from 'primeng/config';
import accordion from '@primeng/themes/lara/accordion';
import autocomplete from '@primeng/themes/lara/autocomplete';
import avatar from '@primeng/themes/lara/avatar';
import badge from '@primeng/themes/lara/badge';
// import base from '@primeng/themes/lara/base';
import blockui from '@primeng/themes/lara/blockui';
import breadcrumb from '@primeng/themes/lara/breadcrumb';
import button from '@primeng/themes/lara/button';
import card from '@primeng/themes/lara/card';
import carousel from '@primeng/themes/lara/carousel';
import cascadeselect from '@primeng/themes/lara/cascadeselect';
import checkbox from '@primeng/themes/lara/checkbox';
import chip from '@primeng/themes/lara/chip';
import colorpicker from '@primeng/themes/lara/colorpicker';
import confirmdialog from '@primeng/themes/lara/confirmdialog';
import confirmpopup from '@primeng/themes/lara/confirmpopup';
import contextmenu from '@primeng/themes/lara/contextmenu';
import datatable from '@primeng/themes/lara/datatable';
import dataview from '@primeng/themes/lara/dataview';
import datepicker from '@primeng/themes/lara/datepicker';
import dialog from '@primeng/themes/lara/dialog';
import divider from '@primeng/themes/lara/divider';
import dock from '@primeng/themes/lara/dock';
import drawer from '@primeng/themes/lara/drawer';
import editor from '@primeng/themes/lara/editor';
import fieldset from '@primeng/themes/lara/fieldset';
import fileupload from '@primeng/themes/lara/fileupload';
import floatlabel from '@primeng/themes/lara/floatlabel';
import galleria from '@primeng/themes/lara/galleria';
import iconfield from '@primeng/themes/lara/iconfield';
import iftalabel from '@primeng/themes/lara/iftalabel';
import image from '@primeng/themes/lara/image';
import imagecompare from '@primeng/themes/lara/imagecompare';
import inlinemessage from '@primeng/themes/lara/inlinemessage';
import inplace from '@primeng/themes/lara/inplace';
import inputchips from '@primeng/themes/lara/inputchips';
import inputgroup from '@primeng/themes/lara/inputgroup';
import inputnumber from '@primeng/themes/lara/inputnumber';
import inputotp from '@primeng/themes/lara/inputotp';
import inputtext from '@primeng/themes/lara/inputtext';
import knob from '@primeng/themes/lara/knob';
import listbox from '@primeng/themes/lara/listbox';
import megamenu from '@primeng/themes/lara/megamenu';
import menu from '@primeng/themes/lara/menu';
import menubar from '@primeng/themes/lara/menubar';
import message from '@primeng/themes/lara/message';
import metergroup from '@primeng/themes/lara/metergroup';
import multiselect from '@primeng/themes/lara/multiselect';
import orderlist from '@primeng/themes/lara/orderlist';
import organizationchart from '@primeng/themes/lara/organizationchart';
import overlaybadge from '@primeng/themes/lara/overlaybadge';
import paginator from '@primeng/themes/lara/paginator';
import panel from '@primeng/themes/lara/panel';
import panelmenu from '@primeng/themes/lara/panelmenu';
import password from '@primeng/themes/lara/password';
import picklist from '@primeng/themes/lara/picklist';
import popover from '@primeng/themes/lara/popover';
import progressbar from '@primeng/themes/lara/progressbar';
import progressspinner from '@primeng/themes/lara/progressspinner';
import radiobutton from '@primeng/themes/lara/radiobutton';
import rating from '@primeng/themes/lara/rating';
import ripple from '@primeng/themes/lara/ripple';
import scrollpanel from '@primeng/themes/lara/scrollpanel';
import select from '@primeng/themes/lara/select';
import selectbutton from '@primeng/themes/lara/selectbutton';
import skeleton from '@primeng/themes/lara/skeleton';
import slider from '@primeng/themes/lara/slider';
import speeddial from '@primeng/themes/lara/speeddial';
import splitbutton from '@primeng/themes/lara/splitbutton';
import splitter from '@primeng/themes/lara/splitter';
import stepper from '@primeng/themes/lara/stepper';
import steps from '@primeng/themes/lara/steps';
import tabmenu from '@primeng/themes/lara/tabmenu';
import tabs from '@primeng/themes/lara/tabs';
import tabview from '@primeng/themes/lara/tabview';
import tag from '@primeng/themes/lara/tag';
import terminal from '@primeng/themes/lara/terminal';
import textarea from '@primeng/themes/lara/textarea';
import tieredmenu from '@primeng/themes/lara/tieredmenu';
import timeline from '@primeng/themes/lara/timeline';
import toast from '@primeng/themes/lara/toast';
import togglebutton from '@primeng/themes/lara/togglebutton';
import toggleswitch from '@primeng/themes/lara/toggleswitch';
import toolbar from '@primeng/themes/lara/toolbar';
import tooltip from '@primeng/themes/lara/tooltip';
import tree from '@primeng/themes/lara/tree';
import treeselect from '@primeng/themes/lara/treeselect';
import treetable from '@primeng/themes/lara/treetable';
import virtualscroller from '@primeng/themes/lara/virtualscroller';
import { DataTableDesignTokens } from '@primeng/themes/types/datatable';

const laraBasedTheme: typeof Lara = {
  primitive: {
    borderRadius: {
      none: '0',
      xs: '2px',
      sm: '4px',
      md: '6px',
      lg: '8px',
      xl: '12px',
    },
    emerald: {
      50: '#ecfdf5',
      100: '#d1fae5',
      200: '#a7f3d0',
      300: '#6ee7b7',
      400: '#34d399',
      500: '#10b981',
      600: '#059669',
      700: '#047857',
      800: '#065f46',
      900: '#064e3b',
      950: '#022c22',
    },
    green: {
      50: '#f0fdf4',
      100: '#dcfce7',
      200: '#bbf7d0',
      300: '#86efac',
      400: '#4ade80',
      500: '#22c55e',
      600: '#16a34a',
      700: '#15803d',
      800: '#166534',
      900: '#14532d',
      950: '#052e16',
    },
    lime: {
      50: '#f7fee7',
      100: '#ecfccb',
      200: '#d9f99d',
      300: '#bef264',
      400: '#a3e635',
      500: '#84cc16',
      600: '#65a30d',
      700: '#4d7c0f',
      800: '#3f6212',
      900: '#365314',
      950: '#1a2e05',
    },
    red: {
      50: '#fef2f2',
      100: '#fee2e2',
      200: '#fecaca',
      300: '#fca5a5',
      400: '#f87171',
      500: '#ef4444',
      600: '#dc2626',
      700: '#b91c1c',
      800: '#991b1b',
      900: '#7f1d1d',
      950: '#450a0a',
    },
    orange: {
      50: '#fff7ed',
      100: '#ffedd5',
      200: '#fed7aa',
      300: '#fdba74',
      400: '#fb923c',
      500: '#f97316',
      600: '#ea580c',
      700: '#c2410c',
      800: '#9a3412',
      900: '#7c2d12',
      950: '#431407',
    },
    amber: {
      50: '#fffbeb',
      100: '#fef3c7',
      200: '#fde68a',
      300: '#fcd34d',
      400: '#fbbf24',
      500: '#f59e0b',
      600: '#d97706',
      700: '#b45309',
      800: '#92400e',
      900: '#78350f',
      950: '#451a03',
    },
    yellow: {
      50: '#fefce8',
      100: '#fef9c3',
      200: '#fef08a',
      300: '#fde047',
      400: '#facc15',
      500: '#eab308',
      600: '#ca8a04',
      700: '#a16207',
      800: '#854d0e',
      900: '#713f12',
      950: '#422006',
    },
    teal: {
      50: '#f0fdfa',
      100: '#ccfbf1',
      200: '#99f6e4',
      300: '#5eead4',
      400: '#2dd4bf',
      500: '#14b8a6',
      600: '#0d9488',
      700: '#0f766e',
      800: '#115e59',
      900: '#134e4a',
      950: '#042f2e',
    },
    cyan: {
      50: '#ecfeff',
      100: '#cffafe',
      200: '#a5f3fc',
      300: '#67e8f9',
      400: '#22d3ee',
      500: '#06b6d4',
      600: '#0891b2',
      700: '#0e7490',
      800: '#155e75',
      900: '#164e63',
      950: '#083344',
    },
    sky: {
      50: '#f0f9ff',
      100: '#e0f2fe',
      200: '#bae6fd',
      300: '#7dd3fc',
      400: '#38bdf8',
      500: '#0ea5e9',
      600: '#0284c7',
      700: '#0369a1',
      800: '#075985',
      900: '#0c4a6e',
      950: '#082f49',
    },
    blue: {
      50: '#eff6ff',
      100: '#dbeafe',
      200: '#bfdbfe',
      300: '#93c5fd',
      400: '#60a5fa',
      500: '#3b82f6',
      600: '#2563eb',
      700: '#1d4ed8',
      800: '#1e40af',
      900: '#1e3a8a',
      950: '#172554',
    },
    indigo: {
      50: '#eef2ff',
      100: '#e0e7ff',
      200: '#c7d2fe',
      300: '#a5b4fc',
      400: '#818cf8',
      500: '#6366f1',
      600: '#4f46e5',
      700: '#4338ca',
      800: '#3730a3',
      900: '#312e81',
      950: '#1e1b4b',
    },
    violet: {
      50: '#f5f3ff',
      100: '#ede9fe',
      200: '#ddd6fe',
      300: '#c4b5fd',
      400: '#a78bfa',
      500: '#8b5cf6',
      600: '#7c3aed',
      700: '#6d28d9',
      800: '#5b21b6',
      900: '#4c1d95',
      950: '#2e1065',
    },
    purple: {
      50: '#faf5ff',
      100: '#f3e8ff',
      200: '#e9d5ff',
      300: '#d8b4fe',
      400: '#c084fc',
      500: '#a855f7',
      600: '#9333ea',
      700: '#7e22ce',
      800: '#6b21a8',
      900: '#581c87',
      950: '#3b0764',
    },
    fuchsia: {
      50: '#fdf4ff',
      100: '#fae8ff',
      200: '#f5d0fe',
      300: '#f0abfc',
      400: '#e879f9',
      500: '#d946ef',
      600: '#c026d3',
      700: '#a21caf',
      800: '#86198f',
      900: '#701a75',
      950: '#4a044e',
    },
    pink: {
      50: '#fdf2f8',
      100: '#fce7f3',
      200: '#fbcfe8',
      300: '#f9a8d4',
      400: '#f472b6',
      500: '#ec4899',
      600: '#db2777',
      700: '#be185d',
      800: '#9d174d',
      900: '#831843',
      950: '#500724',
    },
    rose: {
      50: '#fff1f2',
      100: '#ffe4e6',
      200: '#fecdd3',
      300: '#fda4af',
      400: '#fb7185',
      500: '#f43f5e',
      600: '#e11d48',
      700: '#be123c',
      800: '#9f1239',
      900: '#881337',
      950: '#4c0519',
    },
    slate: {
      50: '#f8fafc',
      100: '#f1f5f9',
      200: '#e2e8f0',
      300: '#cbd5e1',
      400: '#94a3b8',
      500: '#64748b',
      600: '#475569',
      700: '#334155',
      800: '#1e293b',
      900: '#0f172a',
      950: '#020617',
    },
    gray: {
      50: '#f9fafb',
      100: '#f3f4f6',
      200: '#e5e7eb',
      300: '#d1d5db',
      400: '#9ca3af',
      500: '#6b7280',
      600: '#4b5563',
      700: '#374151',
      800: '#1f2937',
      900: '#111827',
      950: '#030712',
    },
    zinc: {
      50: '#fafafa',
      100: '#f4f4f5',
      200: '#e4e4e7',
      300: '#d4d4d8',
      400: '#a1a1aa',
      500: '#71717a',
      600: '#52525b',
      700: '#3f3f46',
      800: '#27272a',
      900: '#18181b',
      950: '#09090b',
    },
    neutral: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#e5e5e5',
      300: '#d4d4d4',
      400: '#a3a3a3',
      500: '#737373',
      600: '#525252',
      700: '#404040',
      800: '#262626',
      900: '#171717',
      950: '#0a0a0a',
    },
    stone: {
      50: '#fafaf9',
      100: '#f5f5f4',
      200: '#e7e5e4',
      300: '#d6d3d1',
      400: '#a8a29e',
      500: '#78716c',
      600: '#57534e',
      700: '#44403c',
      800: '#292524',
      900: '#1c1917',
      950: '#0c0a09',
    },
  },
  semantic: {
    transitionDuration: '0.2s',
    focusRing: {
      width: '0',
      style: 'none',
      color: 'transparent',
      offset: '0',
    },
    disabledOpacity: '0.6',
    iconSize: '1rem',
    anchorGutter: '2px',
    primary: {
      50: '{emerald.50}',
      100: '{emerald.100}',
      200: '{emerald.200}',
      300: '{emerald.300}',
      400: '{emerald.400}',
      500: '{emerald.500}',
      600: '{emerald.600}',
      700: '{emerald.700}',
      800: '{emerald.800}',
      900: '{emerald.900}',
      950: '{emerald.950}',
    },
    formField: {
      paddingX: '0.75rem',
      paddingY: '0.625rem',
      sm: {
        fontSize: '0.875rem',
        paddingX: '0.625rem',
        paddingY: '0.5rem',
      },
      lg: {
        fontSize: '1.125rem',
        paddingX: '0.875rem',
        paddingY: '0.75rem',
      },
      borderRadius: '{border.radius.md}',
      focusRing: {
        width: '{focus.ring.width}',
        style: '{focus.ring.style}',
        color: '{focus.ring.color}',
        offset: '{focus.ring.offset}',
        shadow: '{focus.ring.shadow}',
      },
      transitionDuration: '{transition.duration}',
    },
    list: {
      padding: '0.5rem 0',
      gap: '0',
      header: {
        padding: '0.625rem 1rem 0 1rem',
      },
      option: {
        padding: '0.625rem 1rem',
        borderRadius: '0',
      },
      optionGroup: {
        padding: '0.625rem 1rem',
        fontWeight: '600',
      },
    },
    content: {
      borderRadius: '{border.radius.md}',
    },
    mask: {
      transitionDuration: '0.15s',
    },
    navigation: {
      list: {
        padding: '0.5rem 0',
        gap: '0',
      },
      item: {
        padding: '0.625rem 1rem',
        borderRadius: '0',
        gap: '0.5rem',
      },
      submenuLabel: {
        padding: '0.625rem 1rem',
        fontWeight: '600',
      },
      submenuIcon: {
        size: '0.875rem',
      },
    },
    overlay: {
      select: {
        borderRadius: '{border.radius.md}',
        shadow: '0 2px 12px 0 rgba(0, 0, 0, 0.1)',
      },
      popover: {
        borderRadius: '{border.radius.md}',
        padding: '1rem',
        shadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
      },
      modal: {
        borderRadius: '{border.radius.xl}',
        padding: '1.5rem',
        shadow: '0 1px 3px rgba(0, 0, 0, 0.3)',
      },
      navigation: {
        shadow: '0 2px 12px 0 rgba(0, 0, 0, 0.1)',
      },
    },
    colorScheme: {
      light: {
        surface: {
          0: '#ffffff',
          50: '{slate.50}',
          100: '{slate.100}',
          200: '{slate.200}',
          300: '{slate.300}',
          400: '{slate.400}',
          500: '{slate.500}',
          600: '{slate.600}',
          700: '{slate.700}',
          800: '{slate.800}',
          900: '{slate.900}',
          950: '{slate.950}',
        },
        primary: {
          color: '{primary.500}',
          contrastColor: '#ffffff',
          hoverColor: '{primary.600}',
          activeColor: '{primary.700}',
        },
        highlight: {
          background: '{primary.50}',
          focusBackground: '{primary.100}',
          color: '{primary.700}',
          focusColor: '{primary.800}',
        },
        focusRing: {
          shadow: '0 0 0 0.2rem {primary.200}',
        },
        mask: {
          background: 'rgba(0,0,0,0.4)',
          color: '{surface.200}',
        },
        formField: {
          background: '{surface.0}',
          disabledBackground: '{surface.200}',
          filledBackground: '{surface.50}',
          filledHoverBackground: '{surface.50}',
          filledFocusBackground: '{surface.0}',
          borderColor: '{surface.300}',
          hoverBorderColor: '{primary.color}',
          focusBorderColor: '{primary.color}',
          invalidBorderColor: '{red.400}',
          color: '{surface.700}',
          disabledColor: '{surface.500}',
          placeholderColor: '{surface.500}',
          invalidPlaceholderColor: '{red.600}',
          floatLabelColor: '{surface.500}',
          floatLabelFocusColor: '{primary.600}',
          floatLabelActiveColor: '{surface.500}',
          floatLabelInvalidColor: '{form.field.invalid.placeholder.color}',
          iconColor: '{surface.500}',
          shadow: 'none',
        },
        text: {
          color: '{surface.700}',
          hoverColor: '{surface.800}',
          mutedColor: '{surface.500}',
          hoverMutedColor: '{surface.600}',
        },
        content: {
          background: '{surface.0}',
          hoverBackground: '{surface.100}',
          borderColor: '{surface.200}',
          color: '{text.color}',
          hoverColor: '{text.hover.color}',
        },
        overlay: {
          select: {
            background: '{surface.0}',
            borderColor: '{surface.200}',
            color: '{text.color}',
          },
          popover: {
            background: '{surface.0}',
            borderColor: '{surface.200}',
            color: '{text.color}',
          },
          modal: {
            background: '{surface.0}',
            borderColor: '{surface.200}',
            color: '{text.color}',
          },
        },
        list: {
          option: {
            focusBackground: '{surface.100}',
            selectedBackground: '{highlight.background}',
            selectedFocusBackground: '{highlight.focus.background}',
            color: '{text.color}',
            focusColor: '{text.hover.color}',
            selectedColor: '{highlight.color}',
            selectedFocusColor: '{highlight.focus.color}',
            icon: {
              color: '{surface.400}',
              focusColor: '{surface.500}',
            },
          },
          optionGroup: {
            background: 'transparent',
            color: '{text.color}',
          },
        },
        navigation: {
          item: {
            focusBackground: '{surface.100}',
            activeBackground: '{surface.100}',
            color: '{text.color}',
            focusColor: '{text.hover.color}',
            activeColor: '{text.hover.color}',
            icon: {
              color: '{surface.400}',
              focusColor: '{surface.500}',
              activeColor: '{surface.500}',
            },
          },
          submenuLabel: {
            background: 'transparent',
            color: '{text.color}',
          },
          submenuIcon: {
            color: '{surface.400}',
            focusColor: '{surface.500}',
            activeColor: '{surface.500}',
          },
        },
      },
      dark: {
        surface: {
          0: '#ffffff',
          50: '{zinc.50}',
          100: '{zinc.100}',
          200: '{zinc.200}',
          300: '{zinc.300}',
          400: '{zinc.400}',
          500: '{zinc.500}',
          600: '{zinc.600}',
          700: '{zinc.700}',
          800: '{zinc.800}',
          900: '{zinc.900}',
          950: '{zinc.950}',
        },
        primary: {
          color: '{primary.400}',
          contrastColor: '{surface.900}',
          hoverColor: '{primary.300}',
          activeColor: '{primary.200}',
        },
        highlight: {
          background: 'color-mix(in srgb, {primary.400}, transparent 84%)',
          focusBackground: 'color-mix(in srgb, {primary.400}, transparent 76%)',
          color: 'rgba(255,255,255,.87)',
          focusColor: 'rgba(255,255,255,.87)',
        },
        focusRing: {
          shadow:
            '0 0 0 0.2rem color-mix(in srgb, {primary.color}, transparent 80%)',
        },
        mask: {
          background: 'rgba(0,0,0,0.6)',
          color: '{surface.200}',
        },
        formField: {
          background: '{surface.950}',
          disabledBackground: '{surface.700}',
          filledBackground: '{surface.800}',
          filledHoverBackground: '{surface.800}',
          filledFocusBackground: '{surface.950}',
          borderColor: '{surface.600}',
          hoverBorderColor: '{primary.color}',
          focusBorderColor: '{primary.color}',
          invalidBorderColor: '{red.300}',
          color: '{surface.0}',
          disabledColor: '{surface.400}',
          placeholderColor: '{surface.400}',
          invalidPlaceholderColor: '{red.400}',
          floatLabelColor: '{surface.400}',
          floatLabelFocusColor: '{primary.color}',
          floatLabelActiveColor: '{surface.400}',
          floatLabelInvalidColor: '{form.field.invalid.placeholder.color}',
          iconColor: '{surface.400}',
          shadow: 'none',
        },
        text: {
          color: '{surface.0}',
          hoverColor: '{surface.0}',
          mutedColor: '{surface.400}',
          hoverMutedColor: '{surface.300}',
        },
        content: {
          background: '{surface.900}',
          hoverBackground: '{surface.800}',
          borderColor: '{surface.700}',
          color: '{text.color}',
          hoverColor: '{text.hover.color}',
        },
        overlay: {
          select: {
            background: '{surface.900}',
            borderColor: '{surface.700}',
            color: '{text.color}',
          },
          popover: {
            background: '{surface.900}',
            borderColor: '{surface.700}',
            color: '{text.color}',
          },
          modal: {
            background: '{surface.900}',
            borderColor: '{surface.700}',
            color: '{text.color}',
          },
        },
        list: {
          option: {
            focusBackground: '{surface.800}',
            selectedBackground: '{highlight.background}',
            selectedFocusBackground: '{highlight.focus.background}',
            color: '{text.color}',
            focusColor: '{text.hover.color}',
            selectedColor: '{highlight.color}',
            selectedFocusColor: '{highlight.focus.color}',
            icon: {
              color: '{surface.500}',
              focusColor: '{surface.400}',
            },
          },
          optionGroup: {
            background: 'transparent',
            color: '{text.color}',
          },
        },
        navigation: {
          item: {
            focusBackground: '{surface.800}',
            activeBackground: '{surface.800}',
            color: '{text.color}',
            focusColor: '{text.hover.color}',
            activeColor: '{text.hover.color}',
            icon: {
              color: '{surface.500}',
              focusColor: '{surface.400}',
              activeColor: '{surface.400}',
            },
          },
          submenuLabel: {
            background: 'transparent',
            color: '{text.color}',
          },
          submenuIcon: {
            color: '{surface.500}',
            focusColor: '{surface.400}',
            activeColor: '{surface.400}',
          },
        },
      },
    },
  },
  components: {
    accordion,
    autocomplete,
    avatar,
    badge,
    blockui,
    breadcrumb,
    button,
    datepicker,
    card,
    carousel,
    cascadeselect,
    checkbox,
    chip,
    colorpicker,
    confirmdialog,
    confirmpopup,
    contextmenu,
    dataview,
    datatable: {
      ...datatable,
      row: {
        ...datatable.row,
        background: '{content.color}',
      },
    } satisfies DataTableDesignTokens,
    dialog,
    divider,
    dock,
    drawer,
    editor,
    fieldset,
    fileupload,
    iftalabel,
    floatlabel,
    galleria,
    iconfield,
    image,
    imagecompare,
    inlinemessage,
    inplace,
    inputchips,
    inputgroup,
    inputnumber,
    inputotp,
    inputtext,
    knob,
    listbox,
    megamenu,
    menu,
    menubar,
    message,
    metergroup,
    multiselect,
    orderlist,
    organizationchart,
    overlaybadge,
    popover,
    paginator,
    password,
    panel,
    panelmenu,
    picklist,
    progressbar,
    progressspinner,
    radiobutton,
    rating,
    scrollpanel,
    select,
    selectbutton,
    skeleton,
    slider,
    speeddial,
    splitter,
    splitbutton,
    stepper,
    steps,
    tabmenu,
    tabs,
    tabview,
    textarea,
    tieredmenu,
    tag,
    terminal,
    timeline,
    togglebutton,
    toggleswitch,
    tree,
    treeselect,
    treetable,
    toast,
    toolbar,
    virtualscroller,
    tooltip,
    ripple,
  },
};

// Create a custom theme based on Lara that matches the old SagaBlue theme
export const padspinPrimengPreset: ThemeType = definePreset(Lara, {
  // Define primitive tokens
  primitive: {
    blue: palette('#0659a9'), // Your primary blue color
    green: palette('#00c255'),
  },

  // Define semantic tokens
  semantic: {
    primary: palette('#0659a9'),

    // Color scheme specific tokens
    colorScheme: {
      light: {
        primary: {
          color: '{blue.500}', // Using your primary blue
          contrastColor: '#ffffff',
          hoverColor: '{blue.400}',
          activeColor: '{blue.300}',
        },
        highlight: {
          background: '{blue.100}',
          focusBackground: '{blue.200}',
          color: '{blue.500}',
          focusColor: '{blue.500}',
        },
        focusRing: {
          shadow: '0 0 0 0.2rem rgba(6, 89, 169, 0.2)', // Based on your primary color
        },
      },
      dark: {
        primary: {
          color: '{blue.500}',
          contrastColor: '#ffffff',
          hoverColor: '{blue.400}',
          activeColor: '{blue.300}',
        },
        highlight: {
          background: '{blue.800}',
          focusBackground: '{blue.700}',
          color: '#ffffff',
          focusColor: '#ffffff',
        },
        focusRing: {
          shadow: '0 0 0 0.2rem rgba(6, 89, 169, 0.4)', // Based on your primary color
        },
      },
    },
  },

  components: {
    datatable: {},
    menu: {
      item: {
        padding: '12px 16px 12px 16px',
      },
      list: {
        // padding: '12px 16px 12px 16px',
      },
    },
    button: {
      colorScheme: {
        light: {
          root: {
            secondary: {
              color: '#ffffff',
              background: '{green.500}',
              borderColor: 'transparent',
              hoverBackground: '{green.300}',
              hoverColor: '#ffffff',
              hoverBorderColor: 'transparent',
              activeBackground: '{green.400}',
              activeColor: '#ffffff',
              activeBorderColor: 'transparent',
            },
          },
          outlined: {
            secondary: {
              color: '{green.500}',
              borderColor: '{green.500}',
              activeBackground: '{green.200}',
              hoverBackground: '{green.100}',
            },
          },
        },
      },
    },
  },
} satisfies typeof Lara);
