<section
  class="notch"
  [class.landlord]="bg === 'landlord'"
  [class.tenant]="bg === 'tenant'"
  [class.transparent]="bg === 'transparent'"
></section>
<header
  [class.landlord]="bg === 'landlord'"
  [class.tenant]="bg === 'tenant'"
  [class.transparent]="bg === 'transparent'"
>
  <a routerLink="/" role="heading">
    @if ((bg === 'transparent' || bg === 'landlord') && !isLanding) {
    <img
      src="/assets/web/padspin-logo-white-green-wordmark.svg"
      alt="Padspin"
      (click)="onLogoClick()"
    />
    } @if (bg === 'tenant' && !isLanding) {
    <img src="/assets/web/padspin-logo-white-blue-wordmark.svg" alt="Padspin" />
    } @if (isLanding) {
    <img src="/assets/web/padspin-logo-green-blue-wordmark.svg" alt="Padspin" />
    }
  </a>
  @if (isTenantLandingVisible$ | async) {
  <p-button
    class="landlord-button"
    severity="secondary"
    [rounded]="true"
    [outlined]="true"
    routerLink="/landlord"
    routerLinkActive="hide"
  >
    I'm a Landlord
  </p-button>
  } @if (isLandlordLandingVisible$ | async) {
  <p-button
    [rounded]="true"
    [outlined]="true"
    severity="primary"
    class="post-pad-button"
    routerLink="/post-a-pad"
    routerLinkActive="hide"
    (click)="onPostPadClick()"
    >Post Pad</p-button
  >
  <p-button
    class="tenant-button"
    severity="secondary"
    routerLink="/rent"
    routerLinkActive="hide"
    [rounded]="true"
    [outlined]="true"
    ><span>I'm a Renter</span></p-button
  >
  } @if (isSavedPadsVisible$ | async) {
  <button padspin-tertiary-button [hoverColor]="isTenant ? 'blue' : 'green'">
    Saved Pads
  </button>
  } @if (isViewingsVisible$ | async) {
  <button padspin-tertiary-button [hoverColor]="isTenant ? 'blue' : 'green'">
    Viewings
  </button>
  } @if (primengMenuItems$ | async; as primengMenuItems) {
  <button
    padspin-fab-button
    class="profile-button desktop-only"
    [iconWidth]="24"
    (click)="primengMenu.toggle($event)"
  >
    <img
      icon
      src="/assets/web/icons/perm-identity-white.svg"
      alt="Profile Menu"
    />
  </button>
  <p-menu #primengMenu [popup]="true" [model]="primengMenuItems"></p-menu>
  }
  <button
    padspin-fab-button
    class="mobile-only"
    [iconWidth]="34"
    (click)="toggleMenu()"
  >
    <img icon src="/assets/web/icons/menu-black.svg" alt="Menu" />
  </button>
</header>
