<aside [class.hidden]="!isOpen">
  <button
    padspin-fab-button
    [iconWidth]="22"
    aria-label="close"
    (click)="close()"
  >
    <img src="/assets/web/icons/close.svg" alt="close" />
  </button>
  <nav>
    @if (isPostAPadVisible$ | async) {
    <a padspin-tertiary-button (click)="close()" routerLink="/landlord"
      >Post Pad</a
    >
    }
    <a padspin-tertiary-button (click)="close()" routerLink="/criteria"
      >Matching Criteria</a
    >
    @if ((isLeaseBreakLandingVisible$ | async) === false) {
    <a padspin-tertiary-button (click)="close()" routerLink="/leasebreak"
      >Breaking My Lease</a
    >
    } @if (isLandlordDashboardVisible$ | async) {
    <a
      padspin-tertiary-button
      (click)="close()"
      routerLink="/dashboard/landlord"
      >Landlord Dashboard</a
    >
    } @if (isTenantDashboardVisible$ | async) {
    <a padspin-tertiary-button (click)="close()" routerLink="/dashboard/tenant"
      >Tenant Dashboard</a
    >
    } @if (isAdminDashboardVisible$ | async) {
    <a padspin-tertiary-button (click)="close()" routerLink="/dashboard/admin"
      >Admin Dashboard</a
    >
    } @if ((user$ | async) === null) {
    <a padspin-tertiary-button (click)="login()">Sign-Up / Login</a>
    } @if ((user$ | async) !== null) {
    <a padspin-tertiary-button (click)="logout()">Logout</a>
    } @if ((user$ | async) === null) {
    <a padspin-tertiary-button (click)="register()">Start Matching</a>
    }
  </nav>
</aside>
