import { PrimeNGConfigType } from 'primeng/config';
import { padspinPrimengPreset } from './padspin-primeng-preset';
import Lara from '@primeng/themes/lara';

export const padspinPrimengConfig: PrimeNGConfigType = {
  theme: {
    preset: padspinPrimengPreset,
    options: {
      darkModeSelector: false,
    },
  },
};
