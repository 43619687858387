import { Directive, HostBinding } from '@angular/core';

/**
 * For the title in a dialog. Replacement for `mat-dialog-title`.
 * @see {@link PadspinDialogContentDirective}
 * @see {@link PadspinDialogActionsDirective}
 */
@Directive({
  selector: '[padspinDialogTitle]',
  standalone: true,
})
export class PadspinDialogTitleDirective {
  @HostBinding('class.padspin_dialog_title') readonly class = true;
}
