import { Injectable, inject } from '@angular/core';
import { Auth, authState, User } from '@angular/fire/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FireAuthAuthStateService {
  private readonly auth = inject(Auth);
  authState(): Observable<User | null> {
    return authState(this.auth);
  }
}
