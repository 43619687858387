import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FooterService } from '../footer.service';
import { VersionService } from '../version.service';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import {
  UserTrackingService,
  ScreenTrackingService,
} from '@angular/fire/analytics';
import { HeaderLoadingBarComponent } from '../header-loading-bar/header-loading-bar.component';

import { ToastComponent } from '../toast/toast.component';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { FooterComponent } from '../footer/footer.component';

@Component({
  selector: 'padspin-frontend',
  template: `
    <padspin-header></padspin-header>
    <padspin-side-nav></padspin-side-nav>
    <padspin-toast></padspin-toast>
    <section class="content">
      <padspin-header-loading-bar></padspin-header-loading-bar>
      <router-outlet></router-outlet>
    </section>
    @if (isFooterVisible()) {
    <padspin-footer></padspin-footer>
    }
  `,
  styleUrls: ['./frontend.component.scss'],
  imports: [
    HeaderComponent,
    HeaderLoadingBarComponent,
    RouterModule,
    ToastComponent,
    SideNavComponent,
    FooterComponent,
  ],
  providers: [ScreenTrackingService, UserTrackingService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrontendComponent implements OnInit {
  private readonly footerService = inject(FooterService);
  private readonly versionService = inject(VersionService);

  protected isFooterVisible = toSignal(this.footerService.getVisibility(), {
    initialValue: true,
  });

  async ngOnInit(): Promise<void> {
    await this.versionService.checkMinVersion();
  }
}
