import {
  inject,
  Injectable,
  Injector,
  createNgModule,
  NgModuleRef,
  ApplicationRef,
} from '@angular/core';
import {
  Dialog,
  DialogConfig,
  DialogModule,
  DialogRef,
} from '@angular/cdk/dialog';
import type { ComponentType } from '@angular/cdk/overlay';
import {
  defer,
  EMPTY,
  firstValueFrom,
  Observable,
  shareReplay,
  switchMap,
} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BasePortalOutlet } from '@angular/cdk/portal';

/**
 * Provide a function that imports the modal.
 * @example
 * ```typescript
 * import type { MMInput, MMOutput } from './my-modal.component';
 * inject(ModalLazyLoaderService).openModal<MMOutput, MMInput>({
 *   import: () => import('./my-modal.component')
 * });
 * ```
 */
export type LazilyLoadedModalConfig = {
  import: <C>() => Promise<Record<string, unknown | C>>;
};

/**
 * Open modal dialogs _without_ including them and their transitive dependencies
 * in the bundle.
 *
 * This service requires the `tsconfig.lib.json`.includes to include the
 * directory with the modals. All modals loaded by this service must be in the
 * `modals` directory.
 *
 * ```json
 * {
 *   "include": ["**\/*.ts", "**\/*modal.component.ts"]
 * }
 * ```
 *
 * @usageNotes
 * Open a modal dialog:
 * ```typescript
 * const mlls = inject(ModalLazyLoaderService);
 * const data = { uid: '123' };
 * const dialogRef = await mlls.openModal<void>(
 *   { import: () => import('./my-modal.component') },
 *   data
 * );
 * const output = await firstValueFrom(dialogRef.afterClosed());
 * ```
 *
 * Adding a new modal:
 * ```typescript
 * // The modal must be the first export in the file:
 * @Component({ template: `
 *   <p>Input: {{ input.foo }}</p>
 *   <button (click)="close()">Close</button>
 * `})
 * export class MyModal {
 *   input = inject<MyModalInput>(DIALOG_DATA);
 *   close(): void { inject(DialogRef<MyModal>).close({ bar: 'bar' }); }
 * };
 * export type MyModalInput = { foo: string };
 * export type MyModalOutput = { bar: string };
 *```
 *
 * Use the modal:
 *
 * ```typescript
 * @Injectable({ providedIn: 'root' }) export class MyService {
 *   async openModalWithoutImportingIt(): Promise<void> {
 *     const mlls = inject(ModalLazyLoaderService);
 *     const input: MyModalInput = { foo: 'foo' };
 *     const dialogRef = await mlls.openModal<MyModalOutput, MyModalInput>({
 *       import: () => import('./my-modal.component')
 *     });
 *     const result: MyModalOutput = await firstValueFrom(dialogRef.closed);
 *   }
 * }
 * ```
 */
@Injectable({ providedIn: 'root' })
export class ModalLazyLoaderService {
  private readonly injector = inject(Injector);
  private readonly ar = inject(ApplicationRef);

  private maybeCDKDialogModule?: DialogModule;
  private maybeCDKNgModuleRef?: NgModuleRef<DialogModule>;
  private maybeCDKDialog?: Dialog;

  private async getCDKDialog(): Promise<Dialog> {
    if (this.maybeCDKDialog) {
      return this.maybeCDKDialog;
    }
    const { service, module, ngModuleRef } = await this.loadCDKDialog();
    this.maybeCDKDialog = service;
    this.maybeCDKDialogModule = module;
    this.maybeCDKNgModuleRef = ngModuleRef;

    return service;
  }

  private async loadCDKDialog(): Promise<{
    module: DialogModule;
    ngModuleRef: NgModuleRef<DialogModule>;
    service: Dialog;
  }> {
    const { DialogModule } = await import('@angular/cdk/dialog');
    const ngModuleRef = createNgModule(DialogModule, this.injector);
    const service = ngModuleRef.injector.get(Dialog);
    const module = ngModuleRef.instance;
    return { service, module, ngModuleRef };
  }

  /**
   * Open a modal dialog without including it, and it's transitive dependencies
   * in the application bundle.
   *
   * Generic arguments are `<ReturnType, DataInputType, PortalOutletType>`.
   * @param modal Which modal to open from the list of supported modals.
   * @param config The `DialogConfig` to pass to the modal.
   */
  async openCDKModal<Ret, Dat, C extends BasePortalOutlet = BasePortalOutlet>(
    modal: LazilyLoadedModalConfig,
    config: DialogConfig<Dat, DialogRef<Ret, C>> = {}
  ): Promise<DialogRef<Ret, C>> {
    return firstValueFrom(this.openCDKModal$(modal, config));
  }

  /**
   * Open a modal dialog without including it, and it's transitive dependencies
   * in the application bundle.
   *
   * Generic arguments are `<ReturnType, DataInputType, PortalOutletType>`.
   * @param modal Which modal to open from the list of supported modals.
   * @param config The `DialogConfig` to pass to the modal.
   */
  openCDKModal$<Ret, Dat, C extends BasePortalOutlet = BasePortalOutlet>(
    modal: LazilyLoadedModalConfig,
    config: DialogConfig<Dat, DialogRef<Ret, C>> = {}
  ): Observable<DialogRef<Ret, C>> {
    return defer(() => this.loadCDKModalComponent$<C>(modal)).pipe(
      switchMap((nullableComponent: ComponentType<C> | null) => {
        if (!nullableComponent) {
          return EMPTY;
        }
        return this.openCDKDialog<Dat, Ret, C>(nullableComponent, config);
      }),
      tap(() => {
        // Child components may be rendered un-styled if the dialog is opened
        // right after the page loads.
        this.ar.tick();
      }),
      shareReplay({ bufferSize: 1, refCount: false })
    );
  }

  /**
   * @param modal Path to the modal component relative to
   * ModalLazyLoaderService in the file system, or an object holding a function
   * that imports the modal.
   * @returns The contents of the imported file, or `null` when the import path
   * is invalid.
   */
  private loadCDKModalComponent$<C>(
    modal: LazilyLoadedModalConfig
  ): Observable<ComponentType<C> | null> {
    return defer(async () => {
      return await modal.import();
    }).pipe(
      map((chunk) => Object.values(chunk)[0] as ComponentType<C>),
      shareReplay({ bufferSize: 1, refCount: false })
    );
  }

  private async openCDKDialog<D, R, C extends BasePortalOutlet>(
    component: ComponentType<C>,
    config: DialogConfig<D, DialogRef<R, C>>
  ): Promise<DialogRef<R, C>> {
    const cdkDialog = await this.getCDKDialog();
    return cdkDialog.open(component, config);
  }
}
