import { Injectable } from '@angular/core';
import { ToastMessageOptions } from 'primeng/api';
import { Observable, Subject } from 'rxjs';

export type SnackbarConfig = {
  /** Severity of the message, defaults to 'info'. */
  severity?: 'success' | 'info' | 'warn' | 'error' | 'contrast' | 'secondary';
  /** PrimeNG Icon to show */
  icon?: string;
  /** Duration in ms. */
  duration?: number;
};

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private readonly messages = new Subject<ToastMessageOptions>();
  /**
   * Display a temporary message to the user.
   * Replaces MatSnackBar.open()
   */
  open(message: string, config?: SnackbarConfig): void {
    this.messages.next({
      severity: config?.severity || 'info',
      detail: message,
      icon: config?.icon,
      life: config?.duration,
    });
  }

  getMessages(): Observable<ToastMessageOptions> {
    return this.messages.asObservable();
  }
}
